import React from "react";
import { Inner } from "./styles";
import FullWidth from "../FullWidth";

const Section = ({ children, shrinkTop, ...rest }) => {
  return (
    <FullWidth {...rest}>
      <Inner shrinkTop={shrinkTop}>{children}</Inner>
    </FullWidth>
  );
};

export default Section;
