import styled from "@emotion/styled";
import breakpoints from "../../styles/breakpoints";
import MaxWidth from "../../components/MaxWidth";

export const Inner = styled(MaxWidth)`
  margin: 6rem 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  flex: 1;

  @media (max-width: ${breakpoints.large}) {
    margin-top: ${(props) => props.shrinkTop && "1rem"};
    justify-content: center;
  }

  @media (max-width: ${breakpoints.medium}) {
    margin-top: ${(props) => (props.shrinkTop ? "0rem" : "3rem")};
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 3rem;
  }
`;
