import styled from "@emotion/styled";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";
import { FaLinkedinIn, FaTwitter } from "react-icons/fa";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FooterMain = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  @media (max-width: ${breakpoints.small}) {
    flex-direction: column;
  }
`;

export const FooterSecondary = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${colors.grey};
  font-size: 0.9rem;
  margin-top: 2rem;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-right: ${(props) => (props.mr ? `${props.mr}rem` : null)};
  margin-left: ${(props) => (props.ml ? `${props.ml}rem` : null)};

  @media (max-width: ${breakpoints.small}) {
    margin: 1rem 0rem 0rem 0rem;
  }
`;

export const Group = styled.div`
  display: flex;
  justify-content: center;

  @media (max-width: ${breakpoints.small}) {
    justify-content: flex-start;
  }
`;

export const Copyright = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Image = styled.img`
  height: 1.25rem;
`;
export const SocialIcons = styled.div`
  display: flex;
  flex: row;
  align-items: right;
  justify-content: center;
  flex: 1;
`;
export const SocialButton = styled.div`
  background-color: ${colors.white};
  border: 1px solid ${colors.black};
  border-radius: 100px;
  height: 2.5rem;
  width: 2.5rem;
  padding-top: 4px;
  display: flex;
  flex: column;
  justify-content: center;
  align-items: center;
  margin-right: 0.5rem;

  :hover {
    background-color: ${colors.greyBackground};
    cursor: pointer;
    transition: 0.2s all;
  }
`;

export const LinkedInIcon = styled(FaLinkedinIn)`
  color: ${colors.black};
  padding-top: 1px;
`;

export const TwitterIcon = styled(FaTwitter)`
  color: ${colors.black};
  padding-top: 1px;
`;

export const Links = styled.div`
  display: flex;
`;
