import React from "react";
import WordmarkBlack from "../../images/wordmark-black.svg";
import Section from "../../components/Section";
import Link from "../../components/Link";
import Typography from "../../components/Typography";
import {
  Image,
  Information,
  SocialButton,
  SocialIcons,
  TwitterIcon,
  LinkedInIcon,
  InformationText,
  FooterMain,
  FooterSecondary,
  Copyright,
  Group,
  Links,
  Column,
  Wrapper,
} from "./styles";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <Section>
      <Wrapper>
        <FooterMain>
          <Column mr={1}>
            <Image src={WordmarkBlack} /> <br></br>
            <SocialIcons>
              <SocialButton>
                <a
                  href="https://www.linkedin.com/company/heyoutline/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </SocialButton>
              <SocialButton>
                <a
                  href="https://twitter.com/heyoutline"
                  target="_blank"
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
              </SocialButton>
            </SocialIcons>
          </Column>
          <Group>
            <Column mr={1} ml={1}>
              <Typography variant="body-bold">Resources</Typography>
              <Link to="/privacy">Privacy</Link>
              <Link to="/security">Security</Link>
            </Column>
            <Column ml={1}></Column>
          </Group>
        </FooterMain>
        <FooterSecondary>
          <Copyright>
            <div>San Francisco, California</div>
            <div>{`© ${year} Outline Software, Inc.`}</div>
          </Copyright>
        </FooterSecondary>
      </Wrapper>
    </Section>
  );
};

export default Footer;
