import React from "react"
import { 
	ButtonPrimary, 
	ButtonSecondary, 
	ButtonOutline, 
	ButtonLink, 
	ButtonHeader
} from './styles';

const Button = (props) => {
	const { variant } = props;

	if (variant === 'primary') {
		return <ButtonPrimary {...props} />;

	} else if (variant === 'secondary') {
		return <ButtonSecondary {...props} />;

	} else if (variant === 'outline') {
		return <ButtonOutline {...props} />;

	} else if (variant === 'link') {
		return <ButtonLink {...props} />;

	} else if (variant === 'link-header') {
		return <ButtonHeader {...props} />;
	}

  return <ButtonPrimary {...props} />;
}

Button.defaultProps = {
  type: 'primary',
};

export default Button;