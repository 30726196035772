import { Link as LinkBase } from "gatsby";
import styled from "@emotion/styled";
import colors from "../../styles/colors";

const Link = styled(LinkBase)`
  color: ${colors.black};
  text-decoration: none;

  :hover {
    color: ${colors.primary};
    transition: 0.2s;
  }
`;

export default Link;
