import styled from "@emotion/styled";
import breakpoints from "../../styles/breakpoints";
import MaxWidth from "../../components/MaxWidth";
import Link from "../../components/Link";
import Button from "../Button";

export const NavBar = styled.div`
  padding: 1rem 4rem;
  display: flex;
  justify-content: center;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1;
  background: white;
  box-shadow: ${(props) =>
    props.isScrolling ? "0 0 15px rgba(0,0,0,0.07)" : "none"};
  background-color: ${(props) =>
    props.isScrolling ? "rgba(255,255,255,0.98)" : "none"};

  @media (max-width: ${breakpoints.medium}) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  @media (max-width: ${breakpoints.small}) {
    justify-content: center;
  }
`;

export const InnerNav = styled(MaxWidth)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`;

export const HeaderButton = styled(Button)`
  padding: 0.75rem 1rem;

  @media (max-width: ${breakpoints.small}) {
    display: none;
  }
`;

export const Image = styled.img`
  margin: 0;
  height: 1.25rem;
`;

export const LinkContainer = styled.div`
  float: right;
  align-items: center;
  justify-content: right;
  display: flex;
  flex: column;
`;

export const HeaderLink = styled(Link)`
  font-size: 1rem;
  font-weight: 400;
  margin-right: 2rem;

  @media (max-width: ${breakpoints.small}) {
    margin-right: 0rem;
  }
`;
