import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  NavBar,
  InnerNav,
  Image,
  LinkContainer,
  HeaderButton,
  HeaderLink,
} from "./styles";
import WordmarkBlack from "../../images/wordmark-black.svg";

const Header = ({ siteTitle, location }) => {
  const [isScrolling, setIsScrolling] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      const scroll = window.pageYOffset;
      if (scroll > 0 && !isScrolling) {
        setIsScrolling(true);
      } else if (scroll === 0 && isScrolling) {
        setIsScrolling(false);
      }
    });
    // return () => window.removeListener("scroll");
  });

  return (
    <NavBar isScrolling={isScrolling}>
      <InnerNav>
        <Link to="/">
          <Image src={WordmarkBlack} />
        </Link>
        <LinkContainer>
          <HeaderLink to="/blog">Blog</HeaderLink>
          <HeaderButton
            onClick={() =>
              (window.location = "https://app.heyoutline.com/sign_in")
            }
            variant="outline"
          >
            Sign In
          </HeaderButton>
        </LinkContainer>
      </InnerNav>
    </NavBar>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
