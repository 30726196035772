import React from "react";
import { H1, H2, H3, Body, BodyBold, Subtitle } from "./styles";

const Typography = ({ variant, ...rest }) => {
  switch (variant) {
    case "h1":
      return <H1 {...rest} />;
    case "h2":
      return <H2 {...rest} />;
    case "h3":
      return <H3 {...rest} />;
    case "body":
      return <Body {...rest} />;
    case "body-bold":
      return <BodyBold {...rest} />;
    case "subtitle":
      return <Subtitle {...rest} />;
    default:
      return null;
  }
};

export default Typography;
