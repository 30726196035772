import styled from "@emotion/styled";
import colors from "../../styles/colors";
import breakpoints from "../../styles/breakpoints";

export const H1 = styled.h1`
  font-weight: 700;
  font-size: 3.75rem;
  line-height: 1.2;
  color: ${colors.black};
  text-align: ${(props) => props.textAlign};
  margin-top: 0rem;

  @media (max-width: ${breakpoints.small}) {
    font-size: 3rem;
  }
`;

export const H2 = styled.h2`
  font-weight: 700;
  font-size: 3rem;
  line-height: 1.3;
  color: ${colors.black};
  text-align: ${(props) => props.textAlign};

  @media (max-width: ${breakpoints.small}) {
    font-size: 2.5rem;
  }
`;

export const H3 = styled.h3`
  font-weight: 600;
  font-size: 1rem;
  font-family: "Graphik";
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${colors.black};
  text-align: ${(props) => props.textAlign};
`;

export const Body = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 1.65rem;
  color: ${colors.black};
  text-align: ${(props) => props.textAlign};

  @media (max-width: ${breakpoints.small}) {
    font-size: 16px;
  }
`;

export const BodyBold = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 1.75rem;
  color: ${colors.black};
  text-align: ${(props) => props.textAlign};

  @media (max-width: ${breakpoints.small}) {
    font-size: 16px;
  }
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 0.05rem;
  color: ${colors.grey};
  text-align: ${(props) => props.textAlign};
`;
