import styled from "@emotion/styled";
import colors from "../../styles/colors";

const Button = styled.button`
  border-radius: 8px;
  padding: 1rem 1.5rem;
  border: none;
  font-size: 1rem;
  font-weight: 600;
  white-space: nowrap;
  outline: none;

  :hover {
    cursor: pointer;
    transition: 0.2s;
  }
`;

export const ButtonPrimary = styled(Button)`
  background-color: ${colors.primary};
  color: ${colors.white};
  font-weight: 400;

  :hover {
    background-color: ${colors.primaryDark};
  }

  :disabled {
    opacity: 0.5;

    :hover {
      background-color: ${colors.primary};
    }
  }
`;

export const ButtonSecondary = styled(Button)`
  background-color: ${colors.primary};
  color: ${colors.white};
  font-weight: 400;

  :hover {
    background-color: ${colors.primaryDark};
  }

  :disabled {
    opacity: 0.5;
  }
`;

export const ButtonOutline = styled(Button)`
  background-color: ${colors.white};
  color: ${colors.black};
  border: 1px solid ${colors.greyDark};
  font-weight: 600;

  :hover {
    background-color: ${colors.greyBackground};
  }
`;

export const ButtonLink = styled(Button)`
  background: transparent; 
  border: none;  
  letter-spacing: .05rem;
  color: ${colors.greyDark};
  font-weight: 600;

  :hover {
    color: ${colors.primary};
    transition: 0.2s;
  }
`;

export const ButtonHeader = styled(Button)`
  background: transparent; 
  border: none; 
  letter-spacing: .1rem;

  font-weight: 400;
  color: ${colors.grey};

  :hover {
    color: ${colors.primary};
    transition: 0.2s;
  }
`;

