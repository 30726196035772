export default {
  primary: "#FF6161",
  primaryLight: "#FDF7F7",
  primaryDark: "#E34444",

  secondary: "#EC7B67",
  secondaryLightest: "#DDE8ED",
  secondaryLight: "#F7BAB4",
  secondaryDark: "#1E6989",

  white: "#fff",
  greyBackground: "#F8F9FA",
  greyLightest: "#E7EAED",
  greyLight: "#C3CAD1",
  grey: "#8796A7",
  greyDark: "#616B75",
  black: "#021A27",
  red: "red"
};
