import React from "react";
import PropTypes from "prop-types";
import { ThemeProvider } from "emotion-theming";
import { Main } from "./styles";
import Header from "../Header";
import Footer from "../Footer";
import theme from "../../styles/theme";
import "normalize.css";
import "./global.css";

const Layout = ({ children, location }) => {
  return (
    <ThemeProvider theme={theme}>
      <meta
        name="google-site-verification"
        content="id-lnp_cYsmUGt69BThz7nOea_GuucZBoFKZkIcMwvI"
      />
      <Header location={location} />
      <Main>{children}</Main>
      <Footer />
    </ThemeProvider>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
